import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';


AOS.init({
    duration:'2000'
});

export function LandingScreen(props) {


    return (
        <div className='row landing'>
            <div className='col-md-12 my-auto text-center'>
                
                <h2 style={{color: 'white', fontSize:'130px'}} data-aos='zoom-in'>Patrina Homes</h2>
                <h1 style={{color: 'white'}} data-aos='zoom-out'>“Home Away from Home.”</h1>

                <Link to='/home'>
                    <button className='btn landingbtn' style={{color: 'white', backgroundColor:'#124E66'}}>Explore Our Listings</button>
                </Link>

            </div>
        </div>
    )
};
