import React, { useState } from 'react';
import { Modal, Button, Carousel, CarouselItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function Room({ room, checkin, checkout }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div className='row m-3 p-3 bs'>
            <div className='col-md-4'>
                <img src={room.imageurls[0]} className="smallimg" />
            </div>
            <div className='col-md-7'>
                <h1>{room.name}</h1>
                <b>
                    {" "}
                    <p>Max No. of Guests: {room.maxcount}</p>
                    <p>Phone Number: {room.phonenumber}</p>
                    <p>Type: {room.type}</p>
                </b>

                <div style={{ float: "right" }}>

                    
                {(checkin && checkout) &&
                        <Link to={`/book/${room._id}/${checkin}/${checkout}`}><button className="btn btn-primary mr-2">Book now</button></Link>
                    }
                    <button className='btn btn-primary' onClick={handleShow}>View Details</button>
                </div>
            </div>


            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header>
                    <Modal.Title>{room.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel>
                        {room.imageurls.map(url => {
                            return <CarouselItem>
                                <img
                                    className='d-block w-100 bigimg'
                                    src={url}
                                />
                            </CarouselItem>
                        })}
                    </Carousel>
                    <p>{room.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

