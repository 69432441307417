import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import Loader from '../components/Loader';
import Error from '../components/Error';
import moment from 'moment';
import Swal from 'sweetalert2';
import Payment from '../components/Payment';

export const BookingScreen = () => {
    const { roomid, checkin, checkout } = useParams();
    const [room, setroom] = useState();
    const [bookingId, setBookingId] = useState(null);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState();
    const [amount, setAmount] = useState();
    const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');


    const checkIn = moment(checkin, 'DD-MM-YYYY')
    const checkOut = moment(checkout, 'DD-MM-YYYY')
    const days = moment.duration(checkOut.diff(checkIn)).asDays()+1
    const user = JSON.parse(localStorage.getItem('currentUser'))


    useEffect(() => {
        async function fetchData() {
            if (!localStorage.getItem('currentUser')) {
                window.location = '/login'
            }
            try {
                setloading(true)
                const data = await (await axios.post('/api/rooms/getroombyid', { roomid: roomid })).data
                setroom(data)
                setloading(false)
                setAmount(data.rentperday * days)
            } catch (error) {
                setloading(false)
                seterror(true)
            }
        }

        fetchData();
    }, [roomid])


    async function bookRoom() {
        const bookingDetails = {
            room, checkIn, checkOut, amount, days, userId: user._id, user: JSON.parse(localStorage.getItem('currentUser'))
        }

        try {
            setloading(true)
            const result = await axios.post('/api/bookings/bookroom', bookingDetails)
            setBookingId(result.data.bookingId)
            setloading(false)
            Swal.fire('Congrats', 'Your room has been booked successfully', 'success').then(result=>{
                window.location.href='/profile'
            })
        }
        catch (error) {
            setloading(false)
            console.log(error)
            Swal.fire('Oops', 'Something went wrong, please try again later', 'error')
        }
    }

  
    async function confirmBookingAndSendSMS(bookingDetails, phoneNumber) {
        try {
          const response = await (await axios.post('api/bookings/confirm', {
            phoneNumber,
            bookingDetails,
          })).data;
          setIsBookingConfirmed(true);
            setConfirmationMessage('Booking confirmed! SMS has been sent.');
            console.log('Booking confirmation response:', response.data)
          console.log('Booking confirmation and SMS response:', response.data);
        } catch (error) {
            setIsBookingConfirmed(false);
            setConfirmationMessage('Failed to confirm booking or send SMS.');
            console.error('Error during booking confirmation:', error);
        }
      };

    return (
        <div className='m-5'>
            {loading ? (<Loader/>) : room ? <div>
                <div className='row justify-content-center mt-5 bs'>

                    <div className='col-md-5'>
                        <h1>{room.name}</h1>
                        <img src={room.imageurls[0]} className='bigimg' />
                    </div>
                    <div className='col-md-5'>
                        <div style={{ textAlign: 'right' }}>
                            <h1>Booking Details</h1>
                            <hr />
                            <b>
                                <p>Name: {JSON.parse(localStorage.getItem('currentUser')).name}</p>
                                <p>Check-in: {checkin}</p>
                                <p>Checkout: {checkout} </p>
                                <p>Max No. of Guests: {room.maxcount}</p>

                            </b>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <h1>Amount</h1>
                            <hr />
                            <p>Total Days: {days} </p>
                            <p>Cost per Day: {room.rentperday}</p>
                            <p>Total Amount: {amount} /-</p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Payment onClick={bookRoom && confirmBookingAndSendSMS} amount={amount} />
                        </div>
                    </div>
                </div>


            </div> : (<Error/>)}
        </div>



    )
}
