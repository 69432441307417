// src/components/MpesaPayment.js
import React, { useState } from 'react';
import axios from 'axios';

const Payment = ({ bookingId, amount }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);



  const handlePayment = async () => {
    if (!phoneNumber || !amount ) {
      setPaymentStatus('Phone number is required.');
      return;
    }
    const paymentDetails = {
      phoneNumber, amount
    }

    try {
      setLoading(true);
      setPaymentStatus(null);
      const response = await axios.post('/api/mpesa/stkpush', paymentDetails)
      setPaymentStatus('Payment initiated. Please complete the payment on your phone.');
      console.log('STK Push Response:', response);
    } catch (error) {
      setPaymentStatus('Payment failed. Please try again.');
      console.log('Payment Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 style={{textAlign: 'right'}}>Pay for Your Booking</h1>
      <hr />
      <div class='form-group'>
        <label>Phone Number:</label>
        <input
          type="number"
          placeholder="e.g., 0700000000"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div>
        <label>Amount:</label>
        <input type="number" value={amount} readOnly />
      </div>
      <div style={{ float: 'right' }}>
        <button className='btn btn-primary' onClick={handlePayment} disabled={loading}>
          {loading ? 'Processing...' : 'Pay with M-Pesa'}
        </button>
        {paymentStatus && <p>{paymentStatus}</p>}
      </div>

    </div>
  );
};


export default Payment;