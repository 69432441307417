import React, { useState } from 'react'
import GridLoader from "react-spinners/GridLoader";



export default function Loader(props) {
    let [loading, setLoading] = useState(true);
 
    return (
        <div style={{marginTop: '200px'}}>
                <div className="sweet-loading text-center">

<GridLoader
    color='#034951'
    loading={loading}
    css=''
    size={30}
    aria-label="Loading Spinner"
    data-testid="loader"
/>
</div>
        </div>
    
    )
}
