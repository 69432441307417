import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Room } from "../components/Room";
import Loader from '../components/Loader';
import Error from '../components/Error';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export function Home(props) {
    const [rooms, setrooms] = useState([])
    const [loading, setloading] = useState()
    const [checkIn, setCheckIn] = useState()
    const [checkOut, setCheckOut] = useState()
    const [duplicaterooms, setduplicaterooms] = useState()
    useEffect(() => {
        async function fetchData() {
            try {
                setloading(true)
                const data = await (await axios.get('/api/rooms/allrooms')).data
                setrooms(data)
                setduplicaterooms(data)
                setloading(false)
            } catch (error) {
                console.log(error)
                setloading(false)
            }
        }

        fetchData();
    }, []);


    const filterByDate = dates => {
        setCheckIn(dates[0].format('DD-MM-YYYY'))
        setCheckOut(dates[1].format('DD-MM-YYYY'))

        let temp = []
        for (const room of duplicaterooms) {
            let availability = false
            if (room.currentbookings.length > 0) {
                for (const booking of room.currentbookings) {
                    if (!moment(moment(dates[0]).format('DD-MM-YYYY')).isBetween(
                        booking.checkIn, booking.checkOut) &&
                        !moment(moment(dates[1]).format('DD-MM-YYYY')).isBetween(
                            booking.checkIn, booking.checkOut)

                    ) {
                        if (
                            dates[0].format('DD-MM-YYYY') !== booking.checkIn &&
                            dates[0].format('DD-MM-YYYY') !== booking.checkOut &&
                            dates[1].format('DD-MM-YYYY') !== booking.checkIn &&
                            dates[1].format('DD-MM-YYYY') !== booking.checkOut
                        ) { availability = true }
                    }
                }
            } else { availability = true }
            if (availability === true) {
                temp.push(room)
            }
        }
        setrooms(temp)
        console.log(temp)
    }




    return (
        <div className='container'>
            <div className='row mt-5'>
                <div className='col-md-3'>
                    <RangePicker disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))} onChange={filterByDate} format='DD-MM-YYYY' className='m-2' />
                </div>
            </div>
            <div className='row justify-content-center mt-5'>
                {loading ? (
                    <Loader />
                ) : (
                    rooms.map((room) => {
                        return <div className="col-md-9 mt-2">
                            <Room room={room} checkin={checkIn} checkout={checkOut} />
                        </div>

                    })
                )}

            </div>
        </div>
    );
}
