import React, { useState } from 'react'
import axios from 'axios';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Success from '../components/Success';

export function Register(props) {
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [cpass, setcpass] = useState('')
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState()
    const [success, setsuccess] = useState()


    const register = async () => {
        if (password === cpass) {
            const user = {
                name, email, password, cpass
            }

            try {
                setloading(true);
                const result = await(await axios.post('/api/users/register', user)).data;
                setloading(false);
                setsuccess(true);
		window.location.href = '/login';

                setname('')
                setemail('')
                setpassword('')
                setcpass('')
            } catch (error) {
                console.log(error);
                setloading(false);
                seterror(true);
            }
        } else { alert('Passwords do not match!') }
    }

    return (
        <div>

            {loading && (<Loader/>)}
            <div className='row justify-content-center mt-5'>
                <div className='col-md-5 mt-5'>
                    {success && (<Success success='Registration completed successfully. Please log in.'/>)}
                    <div  className='bs'>
                        <h3>Register</h3>
			{error && (<Error error='User already exists'/>)}
                        <input
                        type="text"
                        className="form-control"
                        placeholder="name"
                        value={name}
                        onChange={event => setname(event.target.value)}
                    />
                    <input
                        type="email"
                        className="form-control"
                        placeholder="email"
                        value={email}
                        onChange={event => setemail(event.target.value)}
                    />
                    <input
                        type="password"
                        className="form-control"
                        placeholder="password"
                        value={password}
                        onChange={event => setpassword(event.target.value)}
                    />
                    <input
                        type="password"
                        className="form-control"
                        placeholder="confirm password"
                        value={cpass}
                        onChange={event => setcpass(event.target.value)}
                    />
                    <button className="btn btn-primary mt-3" onClick={register}>Register</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
