import logo from './logo.svg';
import './App.css';
import { Navbar } from './components/Navbar';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { Home } from './screens/Home';
import { BookingScreen } from './screens/BookingScreen';
import { Register } from './screens/Register';
import { Login } from './screens/Login';
import { Profile } from './screens/Profile';
import { Admin } from './screens/Admin';
import { LandingScreen } from './screens/LandingScreen';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <BrowserRouter>
      <Routes>
      <Route exact path="/home" element={<Home/>} />
      <Route exact path="/book/:roomid/:checkin/:checkout" element={<BookingScreen/>} />
      <Route exact path="/register" element={<Register/>} />
      <Route exact path="/login" element={<Login/>} />
      <Route exact path="/profile" element={<Profile/>} />
      <Route exact path="/admin" element={<Admin/>} />
      <Route exact path="/" element={<LandingScreen/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
