import { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import axios from 'axios'
import Loader from '../components/Loader';
import Error from '../components/Error';
import { Tag, Divider } from 'antd';
import Swal from 'sweetalert2';

/*import { UserOutlined, BookOutlined, BookFilled } from '@ant-design/icons'*/

const TabPane = Tabs
const user = JSON.parse(localStorage.getItem('currentUser'))

export function Profile(props) {
    useEffect(() => {
        if (!user) {
            window.location.href = '/login'
        }
    }, [])

    return (
        <div className='ml ml-3 mt-3 bs mr-3'>
            <Tabs defaultActiveKey="1">
                <TabPane tab='Profile' key='1'>
                    <h3>My Profile</h3><br />
                    <p><b>Name:</b> {user.name}</p>
                    <p><b>Email:</b> {user.email}</p>
                    <p><b>isAdmin:</b> {user.isAdmin ? 'YES' : 'NO'}</p>
                </TabPane>
                <TabPane tab='Bookings' key='2'>
                    <MyBookings />

                </TabPane>
            </Tabs>

        </div>
    )
}

const MyBookings = () => {
    const [bookings, setbookings] = useState([])
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                setloading(true)
                const data = (await axios.post('/api/bookings/getbookingsbyuserid', { userid: user._id })).data
                setbookings(data)
                setloading(false)
            } catch (error) {
                console.log(error)
                setloading(false)
                seterror(true)
            }
        }
        fetchData();
    }, [])

    const cancelBooking = async (bookingId, roomId) => {
        try {
            setloading(true)
            const result = (await axios.post('/api/bookings/cancelbooking', { bookingId, roomId })).data
            console.log(result)
            setloading(false)
            Swal.fire('Congrats', 'Your booking has been cancelled!', 'success').then(result => {
                window.location.reload()
            })

        } catch (error) {
            console.log(error)
            setloading(false)
            Swal.fire('Oops', 'Something went wrong', 'error')
        }
    }
    return (
        <div className="row">
            <div className="col-md-6">
                {loading && <Loader />}
                {bookings && bookings.map(booking => {
                    return (
                        <div>
                            <h4>{booking.room}</h4>
                            <p><b>Booking ID:</b> {booking._id}</p>
                            <p><b>Transaction ID:</b> {booking.txnId}</p>
                            <p><b>Check-In:</b> {booking.checkIn}</p>
                            <p><b>Checkout:</b> {booking.checkOut}</p>
                            <p><b>Amount:</b> {booking.amount}/-</p>
                            <p><b>Status:</b> {booking.status == 'booked' ? <Tag color="green">CONFIRMED</Tag> : <Tag color="red">CANCELLED</Tag>}</p>

                            {booking.status !== 'cancelled' && (<div className='text-right'>

                                <button className='btn btn-primary' onClick={() => { cancelBooking(booking._id, booking.roomId) }}>CANCEL BOOKING</button>
                            </div>)}

                        </div>
                    )
                })}
            </div>
        </div>
    )
}
