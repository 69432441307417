import React, { useState } from 'react'
import axios from 'axios';
import Loader from '../components/Loader';
import Error from '../components/Error';
import Success from '../components/Success';

export function Login(props) {
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)
    const [success, setsuccess] = useState(false)



    const login = async () => {
        const user = {
            email, password
        }
        try {
            setloading(true);
            const result = await (await axios.post('/api/users/login', user)).data

            localStorage.setItem('currentUser', JSON.stringify(result))
            window.location.href = '/home'
        } catch (error) {
            console.log(error);
            setloading(false);
            seterror(true);
        }

    }
    return (
        <div>
            {loading && (<Loader />)}
            <div className='row justify-content-center mt-5'>
                <div className='col-md-5 mt-5'>
                    <div className='bs'>
                        <h3>Login</h3>
                        {error && <Error error='Invalid Credentials' />}
                        {success && (<Success success='User Login Successful' />)}
                        <input
                            type="email"
                            className="form-control"
                            placeholder="email"
                            value={email}
                            onChange={event => setemail(event.target.value)}
                        />
                        <input
                            type="password"
                            className="form-control"
                            placeholder="password"
                            value={password}
                            onChange={event => setpassword(event.target.value)}
                        />
                        <button className="btn btn-primary mt-3" onClick={login}>Login</button>
                        <br/>
                        <a style={{color:'grey'}} href="/register" className="mt-3">New User? Click Here to Register</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

